import React, { useContext, useEffect, useState } from "react"
import CommunityHero from "~/components/configurable/CommunityHero"
import Heading from "~/components/configurable/Heading"
import Block from "../configurable/Block"
import * as PPAill from "~/vectors/PPA_anim.inline.svg"
import * as PPAillVertical from "~/vectors/PPA_anim--vertical.inline.svg"
import * as Process2 from "~/vectors/process2.inline.svg"
import * as Church from "~/vectors/church.inline.svg"
import BlockCTA from "../configurable/BlockCTA"
import TickList from "../configurable/TickList"
import { imageNodesFilter } from "~/utils"
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import Col9 from "../grid/Col9"
import "./for-your-community.scss"
import Col3 from "../grid/Col3"
import ShoutoutNew from "../configurable/ShoutoutNew"
import Section from "../configurable/Section"


const HomepageCommunity = ({ markdownNodes, imageNodes }) => {
  const [heroImage, setHeroImage] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const { setCustomerType } = useContext(CustomerTypeContext)

  useEffect(() => {
    setCustomerType("solartogether")

    // setHeroImage(imageNodesFilter(imageNodes, "group284.png"))
    setHeroImage(imageNodesFilter(imageNodes, "heropeople.png"))

    setLoading(false)
  }, [imageNodes])

  return (
    <div className="homepage" style={{overflow: "hidden", opacity: loading ? 0 : 1}}>
      <div style={{backgroundColor:'#3c96c5' }} >
       <CommunityHero/>
      </div>
      <Block className="community-wrapper homepage-content-wrapper">
        <div className="container container--column illustrated-house-block" >
          <Heading underlined>Solar for everyone </Heading>
          <p className="bold-formatting">
            An increasing number of communities and housing associations are <strong>taking advantage of the support available</strong> from investors, private organisations, and governmental programs to bring renewable technology to their locality while generating revenue for the organisation. A few of the schemes which we offer and are a part of are: <a href="/for-your-community#solar-together">Solar Together</a>, <a href="/for-your-community#ppa">PPAs & Solar Collectives</a>, <a href="/for-your-community#twobuytwo">2buy2</a>.
          </p>
        </div>

        <Section>
          <Heading level={3}>Removing barriers to sustainability </Heading>
              <p className="bold-formatting">
                We believe that wide adoption of renewable technologies requires a wider approach to our concept of sustainability. Climate solutions are only solutions if they can be <strong>sustained long term</strong>. They must be <strong>financially sustainable for all stakeholders</strong>, including local governments and investors, and they must include all strata of society.
              </p>

        </Section>
        <div className="process-ill-cont" >
            <Process2 className="process-ill-item" style={{ maxWidth: "70%" }} />
          </div>
        </Block>
        <div className="divider"  />
      <Block>
        <Section>
          <div className="container" id="solar-together" >
            <Heading level={3} underlined>Solar Together Scheme</Heading>
            <div className="row">
              <Col9>
                <p className="bold-formatting">
                  This is a group-buying programme that brings together households and local authorities across the country to get high quality solar panels at <strong>highly competitive prices</strong>.
                </p>
                <TickList>
                  <li>
                    The scheme is now open to selected locations residents who own their own house or have permission from the landlord to install a solar PV, enabling them to install solar technology on their homes at an affordable price.
                  </li>
                </TickList>
                <div >
                  <BlockCTA
                    secondary url="/solar-together"
                    arrow="right">
                    Find out more
                  </BlockCTA>
                </div>
              </Col9>
              <Col3>
                <img src={require('../../images/solar-together-logo.png')} alt="" />
              </Col3>
            </div>
          </div>
        </Section>
      </Block>


        <ShoutoutNew
          color={'#3c96c5'}
          image={require('../../images/comm1.png')}
          text={
            <>
              <Heading underlined>The green future today </Heading>
              <p>
              Through viable business models that remove the privilege from sustainability the green future can be achieved, and there is ample support for local communities to join that future. Get in touch to find out how you can benefit from the oportunities available.
              </p>
              <BlockCTA
                  url="/contact-us"
                  arrow="right">
                  Find out more
                </BlockCTA>
            </>
          }
        />


      <Block>
        <Section>
          <div className="container" id="ppa" >
            <Heading level={3} underlined>PPAs – Fully funded PV for Landlords</Heading>
            <p className="bold-formatting">
            A PPA funded Solar PV installation is available for Councils, Housing Associations, Property Managers and Business owners whereby solar panels and the installation of the system are provided free of charge with the property owner/tenant having to pay for just the energy they use at a reduced rate. Green Energy Together will work with the Council, Housing Associations, Property Managers or Business owners to provide the ideal system for each property with them receiving a percentage of the profits or a fixed sum per 1,000 installations.
            </p>
          </div>
        </Section>
          <div className="PPA-ill-cont"  >
            <PPAill className="PPA-ill-item hidden-xs" style={{minWidth:"60vw"}} />
            <PPAillVertical className="PPA-ill-item--vertical visible-xs" style={{minWidth:"60vw"}} />
          </div>
      </Block>



        <ShoutoutNew
          color={'#70b33b'}
          image={require('../../images/comm3.png')}
          text={
            <>
              <Heading underlined>PPA benefits  </Heading>
              <p>
              Benefits include cutting carbon emissions significantly, produce cheaper electricity for tenants and <strong>direct payments</strong> to the landlord housing association or council <strong>for every installation </strong>
              </p>
              <BlockCTA navy url="/PPA">Learn more</BlockCTA>
            </>
          }
        />

      {/* 
      <div className="container" style={{marginTop: 50}}>

      </div> */}
      <Block>
        <Section>
          <div className="container" id="twobuytwo">
            <div className="row church_row">
              <Col9>
                <Heading level={3} underlined>2buy2 - Church buying  </Heading>
                <p className="bold-formatting">
                  <strong>Church Buying</strong> is a purchasing service provided by 2buy2, a procurement business committed to protecting the work of the Church. Since 2009 Church Buying has been a trusted adviser and provider of procurement support to faith organisations, <strong>enabling millions</strong> to be invested in the mission and ministry of the Church rather than wasted in overpriced deals.
                </p>
                <Heading level={4} >Can your church run on solar?</Heading>
                <p className="bold-formatting">
                  Church Buying helps churches and others reduce time and money spent on day-to-day purchases and long-term planning needs.
                </p>
                
                  <BlockCTA
                    secondary url="/twobuytwo"
                    arrow="right">
                    Find out more
                  </BlockCTA>
                
              </Col9>
              <Col3 className="church_container" >
                <Church className="church_item" />
              </Col3>
            </div>
          </div>
        </Section>
      </Block>

    </div>
  )
}

export default HomepageCommunity
