import React, { FunctionComponent } from "react"
import "./styles.scss"
import Animate from "~/components/olc-framework/Animate"
import Heading from "../Heading"
import BlockCTA from "../../configurable/BlockCTA"
import * as HouseInterior from "~/vectors/house_interior_no_sun.inline.svg"

const CommunityHero: FunctionComponent<any> = ({ className = "", children, ...props }) => {
  return (
    <Animate
      properties={["opacity", "transform"]}
      startValues={["0", "translateY(40px) rotate(0.5deg)"]}
      endValues={["1", "translateY(0) rotate(0deg)"]}
      delay="0s"
    >
      <div className="commHero">
        <div className="commHero__content">
          <div className="commHero__text">
            <Heading className="commHero__text__h1" >
            A better price together
            </Heading>
            <p className="commHero__text__p">
            We offer a range of community schemes to ensure all are able to benefit from solar energy
            </p>
            <BlockCTA navy url={'/quote'} arrow="right" className="comHero__image__button">
                Get a quote
            </BlockCTA>
          </div>
          <div className="commHero__image">
              <HouseInterior />
          </div>
        </div>
      </div>
    </Animate>
  )
}

export default CommunityHero
